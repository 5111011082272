import type { MetaFunction } from "@remix-run/node";
import clayImage from "../images/clay.jpg?url";

import {
	faFacebook,
	faGithub,
	faLinkedin,
	faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const meta: MetaFunction = () => {
	return [
		{ title: "Clay Diffrient" },
		{ name: "description", content: "Welcome to my personal site" },
	];
};

export default function Index() {
	return (
		<div className="prose prose-sm max-w-none">
			<img
				src={clayImage}
				alt="Clay Diffrient"
				className="rounded-md w-1/3 mr-5 float-left"
			/>

			<h1>Hey, I'm Clay!</h1>

			<p>
				I am a father, a friend, a software engineer, an instructional designer,
				an educational technologist, a volunteer teacher, and an accessibility
				advocate.
			</p>
			<p>
				I'm a staff software engineer at Derivita, a first of its kind STEM
				technology platform and complete computer algebra system. At Derivita, I
				primarily work on the front end of our app, the build system, and pretty
				much anything that comes my way. Ocassionally I've been known to
				reluctantly dabble in the Go code that powers the backend.{" "}
			</p>
			<p>
				Before working at Derivita, I climbed the engineering ranks at
				Instructure. At the end of my tenure there, I was one of the top
				contributors to the front end of the Canvas Learning Management System
				primarily focused on front end architecture.
			</p>
			<p>
				I am passionate about education — specifically the application of
				technology to enhance education! I obtained a Bachelor's of Science in
				Computer Science at Brigham Young University — Idaho as well as a
				Master's of Education in Educational Psychology (IDET) at the University
				of Utah.
			</p>
			<p>
				Sometimes I get a little busy and don’t post much here or make things
				nicer, but you can find me online at several different places. The
				following list is a good place to start.
			</p>
			<div className="list-none flex justify-center items-center space-x-2">
				<a
					className="btn btn-primary btn-sm btn-outline"
					href="https://github.com/claydiffrient"
				>
					<FontAwesomeIcon icon={faGithub} /> GitHub
				</a>

				<a
					className="btn btn-primary btn-sm btn-outline"
					href="https://www.linkedin.com/in/claydiffrient"
				>
					<FontAwesomeIcon icon={faLinkedin} /> LinkedIn
				</a>

				<a
					className="btn btn-primary btn-sm btn-outline"
					href="https://twitter.com/claydiffrient"
				>
					<FontAwesomeIcon icon={faTwitter} /> Twitter
				</a>

				<a
					className="btn btn-primary btn-sm btn-outline"
					href="https://www.facebook.com/clay.diffrient"
				>
					<FontAwesomeIcon icon={faFacebook} /> Facebook
				</a>
			</div>
			<div className="block clear-left"></div>
		</div>
	);
}
